.comment-actions {
  display: flex;
  flex-wrap: wrap;
}

.comment-actions button {
  margin-right: 10px;
  margin-bottom: 10px;
  border: none;
  background: none;
  color: #007bff;
  cursor: pointer;
}

.comment-actions button:hover {
  text-decoration: underline;
}


/* .modal-header-custom {
  background-color: #007bff;
  color: #fff;
}

.modal-body-custom {
  background-color: #f8f9fa;
} */
