@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url('https://cdn.jsdelivr.net/npm/bootstrap@3.4.1/dist/css/bootstrap.min.css');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-family: 'Poppins', sans-serif;
    /* font-size: 22px; */
    /* color: #fff; */
    font-size: 14px;
    /* 
           -ms-text-size-adjust:100%;
    -webkit-text-size-adjust:100% */

}

body {
    min-height: 100vh;
    /* background-color: dodgerblue; */
    background-color: #f0f0f1;
    font-family: "Poppins", Nunito, Helvetica, Arial, sans-serif;
}

.App {
    display: flex;
    flex-direction: column;
    /* flex-direction: inherit; */

    /* justify-content: center; */
    align-items: center;
    min-height: 100vh;
    padding: 1rem 0.5rem;
    /* background-color: rgb(69 72 62); */

}

.nodepressblog-logo {
    width: 15%;
}

.nodepressblog-sidebar-active {
    position: relative !important;
    float: left !important;
    z-index: 9990 !important;
}

section {
    width: 100%;
    /* max-width: 420px;
    min-height: 400px; */
    /* width: 100vh; */

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    background-color: rgb(69 72 62);
}

.home-section {
    /* width: 100%; */
    /* max-width: 420px;
    min-height: 400px; */
    width: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    background-color: rgb(69 72 62);
}

form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    padding-bottom: 1rem;
}

/* a, a:visited {
    color: #fff;
} */

input[type="text"],
input[type="password"],
button,
textarea {
    font-family: 'Poppins', sans-serif;
    /* font-size: 22px; */
    padding: 0.25rem;
    border-radius: 0.5rem;
}

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}


.instructions>svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red !important;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink !important;
    color: firebrick !important;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;
}

.flexGrow {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.avl-balance {
    font-size: 13px;
    text-shadow: none;
    color: #fff;
    background: #000;
    border-radius: 5px;
    padding: 5px 10px !important;
    margin: 0px 10px;
    line-height: normal;
    position: absolute;
    right: 0;
}


.cart-count {
    background-color: #333;
    color: #fff;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    right: -10px;
}

.cart-modal {
    /* position: fixed; */
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.5); */
    display: flex;
    justify-content: center;
    align-items: center;
    /* z-index: 999; */
    /* opacity: 0; */
    /* visibility: hidden; */
    /* transition: opacity 0.3s, visibility 0.3s; */
}

/* Adding Sections */


/* .p, .a, .label, .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
color:#fff;
} */

.container {
    width: 100%;
}



.footer {
    background-color: #000;
}

.footer>p {
    color: #fff;
}

.search-body {
    background-color: #eee;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
}

/* .height{
        height: 100vh;
       } */


.search {
    position: relative;
    box-shadow: 0 0 40px rgba(51, 51, 51, .1);

}

.search>input {

    height: 60px;
    text-indent: 25px;
    border: 2px solid #d6d4d4;


}


.search>input:focus {

    box-shadow: none;
    border: 2px solid blue;


}

.search .fa-search {

    position: absolute;
    top: 20px;
    left: 16px;

}

.search>button {

    position: absolute;
    top: 5px;
    right: 5px;
    height: 50px;
    width: 110px;
    background: blue;

}



.search-control {
    display: block;
    width: 100%;
    min-height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}




.login {
    position: relative;
    box-shadow: 0 0 40px rgba(51, 51, 51, .1);
    width: 50%;
    align-self: center;

}

.login>input {

    height: 60px;
    text-indent: 25px;
    border: 2px solid #d6d4d4;
    color: black;



}


.login>input:focus {

    box-shadow: none;
    border: 2px solid blue;
    color: black;



}

.login .fa-search {
    position: absolute;
    top: 20px;
    left: 16px;
}

.login>button {

    /* position: absolute; */
    top: 5px;
    right: 5px;
    height: 50px;
    width: 50%;
    align-self: center;
    background: #81cf09;

}

.btn-primary {
    /* color: #45483e; */
    color: #fff;
    background-color: #81cf09;

    /* background-color: #337ab7; */
    /* border-color: #2e6da4; */
    border-color: #ffffff;
}

.btn-primary:hover {
    color: #fff;
    background-color: #81cf09;
    border-color: #ffffff;
}

.login-panel {
    align-self: center;
    text-align: center;
}

.login-panel>p {
    color: #fff
}


@keyframes anima {
    from {
        width: 0px;
    }

    to {
        width: 200px;
    }
}

.loginbox {
    border-radius: 10px;
    width: 100px;
    height: 5px;
    background-color: black;
    animation: anima;
    animation-duration: 4s;
    animation-iteration-count: infinite;
}

@keyframes anima {
    from {
        width: 0px;
    }

    to {
        width: 200px;
    }
}


.card-container>h4 {
    color: #286090;

}


.btn-renew {
    background-color: #2c3e50;
    color: #fff
}

.panel-default {
    border-color: #ddd;
}

.panel-red {
    border-top: 3px solid #d9534f;
}

.panel-black {
    border-top: 3px solid #2c3e50;
}

.panel-golden {
    border-top: 3px solid #f0ad4e;
}

.panel-blue {
    border-top: 3px solid #5bc0de;
}

.panel-emerald {
    border-top: 3px solid #2ecc71;
}

.panel-asbestos {
    border-top: 3px solid #7f8c8d;
}



.tiles .tile {
    padding: 12px 20px;
    background-color: #f8f8f8;
    border-right: 1px solid #ccc;

}

.tiles .tile:hover {
    background-color: #f2f2f2;
    cursor: pointer;
}


.tiles .tile a {
    text-decoration: none;
    color: #058;

}

.tile .icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 48px;
    line-height: 1;
    color: #ccc;
}

.tile .stat {
    margin-top: 20px;
    font-size: 40px;
    line-height: 1;
}

.tile .title {
    font-weight: 700;
    color: #888;
    text-transform: uppercase;
    font-size: 12px;
}

.tiles .tile .highlight {
    margin-top: 4px;
    height: 2px;
    border-radius: 2px;
}


.bg-color-blue {
    background-color: #5bc0de;
}

.bg-color-green {
    background-color: #81cf09;
}

.bg-color-red {
    background-color: #d9534f;
}

.bg-color-gold {
    background-color: #f0ad4e;
}

.bg-color-grey {
    background-color: #b7b7b7;
}



.balance {
    text-align: center;
}


.bg-price {
    background-color: #81cf09;
    /* cursor: pointer; */
    background: rgb(223, 240, 216);
    margin-left: 40%;
    /* color: rgb(15, 52, 92); */
}




.list-group {
    /* max-height: 230px; */
    height: 100%;
    overflow: auto;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;

    padding-left: 0;
    margin-bottom: 20px;
    font-size: .9em;


}

#ticket-container {
    width: 60% !important;
    /*border-radius: 25px;*/
    border: 2px solid Black !important;
    padding: 15px 15px 15px 15px !important;
    margin: 20px 20px 20px 20px !important;
    /* background: #A4D3EE !important; */
    overflow: visible !important;
    box-shadow: 5px 5px 2px #888888 !important;
    position: relative !important;
}


#x {
    position: absolute !important;
    background: red !important;
    color: white !important;
    top: -10px !important;
    right: -10px !important;
}

.small {
    color: #888;
}



.table-background {
    margin: 0;
    padding: 9px 10px;
    background-color: #4f5360;
    border: 0;
    border-bottom: 1px solid #fff;
    font-size: .9em;
    color: #fff;
    border-radius: 0;
    text-align: left !important;
}


.table-button {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    padding: 0.01rem;
    border-radius: 0.0rem;

}

.table>thead>tr>th>button {

    border: none;

}

.pagination>button {}

.list-group>a {
    color: #000;
}



.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    padding: 10px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
}


.text-right {
    text-align: right !important;
}

.paid-stamp {
    display: inline-block;
    font-size: 26px;
    color: #5bc0de;
    line-height: 1;
    border: 3px solid;
    padding: 2px 4px;
    border-radius: 6px;
    letter-spacing: 1px;
    transform: rotate(-15deg);
    position: absolute;
    right: 50%;
    top: -8px;


}

.unpaid-stamp {
    display: inline-block;
    font-size: 26px;
    color: #ff2828;
    line-height: 1;
    border: 3px solid;
    padding: 2px 4px;
    border-radius: 6px;
    letter-spacing: 1px;
    transform: rotate(-15deg);
    position: absolute;
    right: 50%;
    top: -8px;
}


/* .table-responsive>table>tbody>tr>td>a{
color: #000 !important;

} */

/* .tr>td>a{
color: #000 !important;

} */
/* 
.td>a{
    background-color: #2ecc71;
color: #000 !important;

} */

/* 
.table-responsive>table>tbody>tr>td>a:visited {
    color: #f00 !important;
}
*/

.table-atd-color {
    color: #000 !important;

    display: block;
    font-size: .9em;
    line-height: 22px;
    border: 2px solid #ccc;
    border-radius: 3px;
    /* background-color: #fff; */
    color: rgb(1, 0, 0) !important;
    text-align: center;


}


.tr>td>a {
    color: #000 !important;
}


.tr>td>a:visited {
    color: #000 !important;

}


#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#customers td,
#customers th {
    border: 1px solid #ddd;
    padding: 8px;
}

#customers tr:nth-child(even) {
    background-color: #f2f2f2;
}

#customers tr:hover {
    background-color: #ddd;
}

#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04AA6D;
    color: white;
}


.th>a,
.th>a:visited {
    color: #337ab7 !important;
}

.td>a,
.td>a:visited {
    color: #337ab7 !important
}




.float-right {
    float: right;
}

/* 
a, a:visited {
    color: #337ab7;
}
a, a:visited {
    color: #fff;
} */
.text-highlight-green {
    --tw-text-opacity: 1;
    color: rgb(0 206 126 / var(--tw-text-opacity));
}

/* .font-bold {
    font-weight: 700;
} */
.text-\[16px\] {
    font-size: 16px;
}

/* .text-center {
    text-align: center;
} */
/* .p-\[10px\] {
    padding: 10px;
} */
.bg-\[\#fff\] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

/* .border-highlight-green {
    --tw-border-opacity: 1;
    border-color: rgb(0 206 126 / var(--tw-border-opacity));
} */
/* .border-solid {
    border-style: solid;
}
.border {
    border-width: 1px;
}
.rounded-\[5px\] {
    border-radius: 5px;
} */
/* .justify-center {
    justify-content: center;
} */
/* .items-center {
    align-items: center;
} */
/* .cursor-pointer {
    cursor: pointer;
} */
.w-\[100px\] {
    width: 100px;
}

/* .flex {
    display: flex;
} */

.mt-\[20px\] {
    margin-top: 20px;
}

.font-semibold {
    font-weight: 600;
}

.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.leading-relaxed {
    line-height: 1.625;
}


.text-\[17px\] {
    font-size: 17px;
}

.bg-bg-light {
    --tw-bg-opacity: 1;
    background-color: rgb(246 250 251 / var(--tw-bg-opacity));
}

.mb-\[40px\] {
    margin-bottom: 40px;
}

.mt-\[20px\] {
    margin-top: 20px;
}


text-\[\#fff\] {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

/* .font-bold {
    font-weight: 700;
} */
/* .text-\[13px\] {
    font-size: 13px;
} */
/* .text-center {
    text-align: center;
} */
/* .px-\[15px\] {
    padding-left: 15px;
    padding-right: 15px;
} */
/* .py-\[5px\] {
    padding-top: 5px;
    padding-bottom: 5px;
} */
/* .p-\[10px\] {
    padding: 10px;
} */
.bg-highlight-green {
    --tw-bg-opacity: 1;
    background-color: rgb(0 206 126 / var(--tw-bg-opacity));
}

.border-highlight-green {
    --tw-border-opacity: 1;
    border-color: rgb(0 206 126 / var(--tw-border-opacity));
}

/* 
.border-solid {
    border-style: solid;
} */
/* .border {
    border-width: 1px;
} */
/* .rounded-\[5px\] {
    border-radius: 5px;
} */
/* .justify-center {
    justify-content: center;
} */
/* .items-center {
    align-items: center;
} */
/* .cursor-pointer {
    cursor: pointer;
} */
.flex {
    display: flex;
}

.text-left {
    text-align: left;
}

.p-\[20px\] {
    padding: 20px;
}

.border-\[\#e6e6e6\] {
    --tw-border-opacity: 1;
    border-color: rgb(230 230 230 / var(--tw-border-opacity));
}

/* .border-solid {
    border-style: solid;
} */
/* .border {
    border-width: 1px;
} */
/* .rounded-\[5px\] {
    border-radius: 5px;
} */
.my-\[20px\] {
    margin-top: 20px;
    margin-bottom: 20px;
}

.relative {
    position: relative;
}

.text-\[\#fff\] {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.font-bold {
    font-weight: 700;
}

.text-\[13px\] {
    font-size: 13px;
}

.text-center {
    text-align: center;
}

.p-\[10px\] {
    padding: 10px;
}

.pl-\[10px\] {
    padding-left: 10px;
}

.bg-highlight-blue {
    --tw-bg-opacity: 1;
    background-color: rgb(47 76 255 / var(--tw-bg-opacity));
}

.border-highlight-blue {
    --tw-border-opacity: 1;
    border-color: rgb(47 76 255 / var(--tw-border-opacity));
}

.border-solid {
    border-style: solid;
}

.border {
    border-width: 1px;
}

.rounded-\[5px\] {
    border-radius: 5px;
}

.justify-center {
    justify-content: center;
}

.items-center {
    align-items: center;
}

.cursor-pointer {
    cursor: pointer;
}

.w-full {
    width: 100%;
}

.txt-full {
    width: 100%;
    height: 350px !important;
}

/* .flex {
    display: flex;
} */
.mt-\[10px\] {
    margin-top: 10px;
}

.attachments {
    /* margin: 0; */
    padding: 5px 10px;
    border: 1px dashed #333333;
    background-color: #dff0d8;
    font-size: 0.9em;
}

.border-ticket {
    background-color: rgb(174, 238, 215);

}

.ticket-body {

    color: #083667;
    background-color: #dff0d8;
    border-color: #f5f5f5;
    border-radius: 15px;
}

.cart-count {
    background-color: #333;
    color: #fff;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    right: -10px;
}



.profile-completion-notification {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f5f5f5;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.1);
}



.update-profile-form>input[type=text],
select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}



.profile-update-remainder {
    position: fixed;
    /* Fix the notification to the bottom of the page */
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    /* White background color */
    color: #000000;
    /* Black text color */
    padding: 10px;
    /* Add some padding to the notification */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* Add a subtle shadow effect */
    z-index: 100;
    /* Make sure the notification is always on top */
    animation: bounce 0.5s;
    /* Add a slight bounce effect */
}

/* Add a slight dim effect when the notification has a "unpaid" status */
.profile-update-remainder.unpaid {
    background-color: rgba(255, 255, 255, 0.5);
    /* Dim the white background color */
    color: rgba(0, 0, 0, 0.5);
    /* Dim the black text color */
}

/* Add the bounce animation */
@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}


.service-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    font-size: 2rem;
    text-align: -webkit-center;
}

.service-loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.tag-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.tag-input-container > input {
  /* padding: 10px; */
      padding: 0.25rem;
    width: 100%;

  border: 1px solid #ddd;
  /* border-radius: 5px 0 0 5px; */
      border-radius: 0.5rem;

  /* outline: none; */
  /* width: 100%; */
  /* box-sizing: border-box; */
}

.nodepress-blog-tags > button {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 0 5px 5px 0;
  background-color: #ddd;
  cursor: pointer;
}

.nodepress-blog-tags > ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.nodepress-blog-tags > ul > li {
  background-color: #ddd;
  /* /* padding: 10px; */
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px; 

    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    /* border-radius: 3px; */
/* 
      float: left;
    margin-right: 25px;
    font-size: 13px;
    line-height: 1.8;
    cursor: default;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; */
}

.tag-delete-button {
  background: transparent;
  border: none;
  cursor: pointer;
  
}

.remove-tag-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGc+CiAgICA8cGF0aCBkPSJNOS45OTk5LDkuOTk5OUw1LjQ5OTk5LDUuNSBMOS45OTk5LDEuMDAwMDEgTDEwLjk5OTksMi4wMDAwMSBMNS41LDYuNTAwMDEgTDEuMDAwMDEsMi4wMDAwMSBMMi4wMDAwMSwxLjAwMDAxIEw2LjUsNS41IEwyLjAwMDAxLDkuOTk5OSBMMS4wMDAwMSw5Ljk5OTkgTDUuNSw2LjQ5OTk5IEw5Ljk5OTksMTAuOTk5OSBMOS45OTk5LDkuOTk5OSBaIiBmaWxsPSJyZ2IoMTU1LDE1NSwxNTUpIi8+CiAgPC9nPgo8L3N2Zz4=');
  /* background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjEwcHgiIGhlaWdodD0iMTBweCIgdmlld0JveD0iMCAwIDEwIDEwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGc+CiAgICA8cGF0aCBkPSJNOS45OTk5LDkuOTk5OUw1LjQ5OTk5LDUuNSBMOS45OTk5LDEuMDAwMDEgTDEwLjk5OTksMi4wMDAwMSBMNS41LDYuNTAwMDEgTDEuMDAwMDEsMi4wMDAwMSBMMi4wMDAwMSwxLjAwMDAxIEw2LjUsNS41IEwyLjAwMDAxLDkuOTk5OSBMMS4wMDAwMSw5Ljk5OTkgTDUuNSw2LjQ5OTk5IEw5Ljk5OTksMTAuOTk5OSBMOS45OTk5LDkuOTk5OSBaIiBmaWxsPSJyZ2IoMTU1LDE1NSwxNTUpIi8+CiAgPC9nPgo8L3N2Zz4='); */
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 8px;
}

.remove-tag-icon:hover {
  /* display: inline-block;
  width: 16px;
  height: 16px; */
   content: "×";
    color: #ffffff;
    background-color: #c900ab;
/* 
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjEwcHgiIGhlaWdodD0iMTBweCIgdmlld0JveD0iMCAwIDEwIDEwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGc+CiAgICA8cGF0aCBkPSJNOS45OTk5LDkuOTk5OUw1LjQ5OTk5LDUuNSBMOS45OTk5LDEuMDAwMDEgTDEwLjk5OTksMi4wMDAwMSBMNS41LDYuNTAwMDEgTDEuMDAwMDEsMi4wMDAwMSBMMi4wMDAwMSwxLjAwMDAxIEw2LjUsNS41IEwyLjAwMDAxLDkuOTk5OSBMMS4wMDAwMSw5Ljk5OTkgTDUuNSw2LjQ5OTk5IEw5Ljk5OTksMTAuOTk5OSBMOS45OTk5LDkuOTk5OSBaIiBmaWxsPSJyZ2IoMTU1LDE1NSwxNTUpIi8+CiAgPC9nPgo8L3N2Zz4=');
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 8px; */
}



.screen-reader-text {
  position: absolute;
  left: -9999px;
}

/* .tag-chips-container {
  display: flex;
  flex-wrap: wrap;
}

.tag-chip {
  background-color: #00ffbf;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  margin: 4px;
  font-size: 14px;
}

.tag-chip-text {
  margin-right: 4px;
}

.tag-chip-delete-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

.remove-tag-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='#000' d='M12 4.7l-5.3 5.3-5.3-5.3-1.4 1.4 5.3 5.3-5.3 5.3 1.4 1.4 5.3-5.3 5.3 5.3 1.4-1.4-5.3-5.3 5.3-5.3z'/></svg>");
  background-repeat: no-repeat;
  background-position: center;
} */

