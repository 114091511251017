
.form-check-label {
padding-left: 2%;
}

/* .customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 100%;
} */

/* .datepicker {
    width: 100%;
} */
.react-datepicker__input-container>input {
        width: 100%;
}

.form-check>label {
    font-weight: normal;
}